const getInitialState = () => ({
	counters: {
		identifiedContacts: 0
	},
	options: {
		activityVisit: {},
		noteVisit: {},
		trackUTM: {},
		trackLeadSource: {},
		trackLeadReferrer: {},
		trackLandingPage: {},
		trackCustomParameters: {}
	},
	settings: {},
	generalErrors: [],
	fieldErrors: {}
});

export default getInitialState;
