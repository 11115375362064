//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import ROUTE_NAMES from 'router/route-names';
import { ContactSourceNames } from 'utils/constants';

export default {
	props: {
		activeContactSource: {
			type: String,
			required: true
		},
		noteEngagementEvents: {
			type: Array,
			required: true
		},
		activityEngagementEvents: {
			type: Array,
			required: true
		}
	},

	computed: {
		/**
		 * @return {Object}
		 */
		emailEngagementRoute() {
			return { name: ROUTE_NAMES.EMAIL_SETTINGS_ENGAGEMENT_RECORDING };
		},

		/**
		 * @return {Boolean}
		 */
		isEmailEngagementSettingsEnabled() {
			return this.isPipedriveConnected || this.isCopperConnected;
		},

		/**
		 * @return {Boolean}
		 */
		isPipedriveConnected() {
			return this.activeContactSource === ContactSourceNames.PIPEDRIVE;
		},

		/**
		 * @return {Boolean}
		 */
		isHubspotCrmConnected() {
			return this.activeContactSource === ContactSourceNames.HUBSPOT;
		},

		/**
		 * @return {Boolean}
		 */
		isCopperConnected() {
			return this.activeContactSource === ContactSourceNames.COPPER;
		},

		/**
		 * @return {String}
		 */
		emailEngagementNotesText() {
			if (!this.noteEngagementEvents.length) {
				return '';
			}

			return `${this.noteEngagementEvents.join(', ')} > note`
				.replace(/^\w/, character => character.toUpperCase());
		},

		/**
		 * @return {String}
		 */
		emailEngagementActivitiesText() {
			if (!this.activityEngagementEvents.length) {
				return '';
			}

			return `${this.activityEngagementEvents.join(', ')} > activity`
				.replace(/^\w/, character => character.toUpperCase());
		}
	}
};

