//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import ROUTE_NAMES from 'router/route-names';

const EVENT_DIALOG_SHOW = 'dialog:show';
const EVENT_DISMISS = 'dismiss';

export default {
	data: () => ({
		verifiedDomainsRoute: { name: ROUTE_NAMES.EMAIL_SETTINGS_DOMAINS },
		bestPracticesUrl: 'https://outfunnel.com/improve-email-deliverability'
	}),

	methods: {
		onShowDialog() {
			this.$emit(EVENT_DIALOG_SHOW);
		},

		onDismiss() {
			this.$emit(EVENT_DISMISS);
		}
	}
};

