import { api } from 'api';
import logger from 'utils/logger';
import router from 'router';
import ROUTE_NAMES from 'router/route-names';
import utils from 'utils';
import SHOW_NOTIFICATION from 'store/modules/notifications/actionTypes';

import {
	DOMAIN_GET,
	DOMAINS_NAVIGATE,
	DOMAIN_VERIFY,
	VALUE_COPY
} from './actionTypes';

import {
	DOMAIN,
	GENERAL_ERRORS
} from './mutationTypes';

export const actions = {
	[DOMAIN_GET]: async ({
		commit,
		rootState: { token }
	}, domainId) => {
		try {
			const domain = await api.user.settings.email.domains.get(token, domainId);

			commit(DOMAIN, domain);
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[DOMAINS_NAVIGATE]: async () => {
		router.push({ name: ROUTE_NAMES.EMAIL_SETTINGS_DOMAINS });
	},

	[DOMAIN_VERIFY]: async ({
		state: { domain },
		commit,
		rootState: { token }
	}) => {
		try {
			const domainData = await api.user.settings.email.domains.verify.post(token, domain._id);

			commit(DOMAIN, domainData);
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}
		}
	},

	[VALUE_COPY]: ({ dispatch }, itemName) => {
		utils.copySelectedText();

		dispatch(
			`notifications/${SHOW_NOTIFICATION}`,
			`${itemName} copied to clipboard`,
			{ root: true }
		);
	}
};
