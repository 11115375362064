import { api } from 'api';
import logger from 'utils/logger';
import router from 'router';
import ROUTE_NAMES from 'router/route-names';

import {
	DOMAINS_GET,
	DOMAIN_EDIT
} from './actionTypes';
import {
	DOMAINS,
	GENERAL_ERRORS
} from './mutationTypes';

export const actions = {
	[DOMAINS_GET]: async ({
		commit,
		rootState: { token }
	}) => {
		try {
			const domains = await api.user.settings.email.domains.get(token);

			commit(DOMAINS, domains);
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[DOMAIN_EDIT]: (context, domainId) => {
		console.log('Here', domainId);
		router.push({
			name: ROUTE_NAMES.EMAIL_SETTINGS_DOMAINS_EDIT,
			params: { id: domainId }
		});
	}
};
