export const getInitialState = () => ({
	fieldErrors: [],
	settings: {},
	options: {
		activityVisit: {},
		noteVisit: {},
		trackUTM: {},
		trackLeadSource: {},
		trackLeadReferrer: {},
		trackLandingPage: {},
		trackCustomParameters: {}
	},
	features: null
});
